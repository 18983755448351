<div class="status">
    <div class="status__content">

        <div  class="status__icon" *ngIf="status.icon">
            <img  [src]="'assets/images/' + status.icon"
                alt="">
        </div>

        <div class="status__heading" *ngIf="status.heading">
            {{status.heading}}
        </div>

        <div class="status__body" *ngIf="status.body">
            {{status.body}}
        </div>

        <div class="status__phone-and-pin-wrapper">
            <cr-avid-phone-and-pin *ngIf="status.showPhoneNumber === true" [pin]="pin" [phone]="phone"></cr-avid-phone-and-pin>
        </div>

    </div>
</div>
