import { Component, Input } from '@angular/core';

@Component({
  selector: 'cr-avid-phone-and-pin',
  templateUrl: './phone-and-pin.component.html',
  styleUrls: ['./phone-and-pin.component.scss']
})
export class PhoneAndPinComponent {
  @Input() pin: string | undefined;
  @Input() phone: string | undefined;
}
