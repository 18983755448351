import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'cr-avid-get-started',
    templateUrl: './get-started.component.html',
    styleUrls: ['./get-started.component.scss'],
})
export class GetStartedComponent {
    @Output() buttonClick = new EventEmitter();

    public buttonClickHandler(): void {
        this.buttonClick.emit();
    }
}
