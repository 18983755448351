import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { fadeIn } from 'src/app/animations/animations';
import { MessageStatus, WebSocketMessage } from 'src/app/models/models';
import { SignalRService } from 'src/app/services/signalr.service';

@Component({
    selector: 'cr-avid-read-aloud',
    templateUrl: './read-aloud.component.html',
    styleUrls: ['./read-aloud.component.scss'],
    animations: [fadeIn],
})
export class ReadAloudComponent implements OnDestroy, OnInit {
    progress: number = 0;
    status: MessageStatus;
    mssg: WebSocketMessage | undefined;
    callStatusConditions: Partial<MessageStatus>[] = [
        'processing',
        'success',
        'error',
        'done',
        'disconnected',
    ];

    @Input() pin: string | undefined;
    @Input() phone: string | undefined;

    private _subscription$: Subscription = new Subscription();

    constructor(
        private signalRService: SignalRService,
        private ref: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.listenForMessages();
    }

    ngOnDestroy(): void {
        this._subscription$.unsubscribe();
    }

    private listenForMessages(): void {
        this._subscription$.add(
            this.signalRService.messages$.subscribe(
                (mssg: WebSocketMessage | undefined) => {
                    this.mssg = mssg;
                    this.status = mssg?.status ?? 'listening';
                    if (this.status && ['success', 'done'].includes(this.status)) {
                        this.progress = this.mssg?.progress ?? 0;
                    }

                    if (mssg?.errorType === 'tryagain') {
                        this.progress = 0;
                    }

                    // if long-polling on signlaR, message come in-between lifecycle hooks
                    // this is needed to refresh the ui so it instantly reflects the new message
                    this.ref.detectChanges();
                }
            )
        );
    }
}
