import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Employee } from 'src/app/models/models';
import { fadeIn } from 'src/app/animations/animations';

@Component({
    selector: 'cr-avid-landing-page-footer',
    templateUrl: './landing-page-footer.component.html',
    styleUrls: ['./landing-page-footer.component.scss'],
    animations: [fadeIn],
})
export class LandingPageFooterComponent {
    @Input() employee?: Employee;
    @Input() rosterEntries?: Employee[];
    @Output() goToRosterPage = new EventEmitter();

    reviewRosterEntries(e: MouseEvent): void {
        e.preventDefault();
        this.goToRosterPage.emit(e);
    }
}
