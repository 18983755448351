<div class="roster-card"
    [@fadeIn]>
    
    <!-- CARD HEADER -->
    <div #cardHeader class="roster-card__header card-header">
        <div class="roster-card__header-content">
            <p> Your Agent Voice ID setup will apply to all of the below roster entries.
                If something doesn’t seem right—please
                contact your dealership admin or <a href="https://www.callrevu.com/contact-support"
                    target="_blank"
                    rel="noopener">CallRevu Support</a>
            </p>
        </div>
    </div>

    <!-- CARD BODY (TABLE)-->
    <div class="roster-card__table-wrapper card-body" (scroll)="onScroll($event)">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Location</th>
                    <th scope="col">Title</th>
                </tr>
            </thead>
            <tbody>
                <!-- TABLE ROW MOBILE -->
                <tr class="d-table-row d-md-none"
                    *ngFor="let entry of entries">
                    <td>
                        {{entry.firstName}} {{entry.lastName}}<br>
                        {{entry.accountName}}<br>
                        {{entry.title}}
                    </td>
                </tr>

                <!-- TABLE ROW DESKTOP -->
                <tr class="d-none d-md-table-row"
                    *ngFor="let entry of entries">
                    <td> {{entry.firstName}} {{entry.lastName}}</td>
                    <td>{{entry.accountName}}</td>
                    <td>{{entry.title}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- CARD FOOTER -->
    <div #cardFooter class="roster-card__footer">
        <button type="button"
            class="btn btn-primary"
            (click)="startEnrollment()">Start Enrollment</button>
    </div>
</div>