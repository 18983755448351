import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { Employee } from 'src/app/models/models';
import { fadeIn } from 'src/app/animations/animations';
@Component({
    selector: 'cr-avid-roster-entries',
    templateUrl: './roster-entries.component.html',
    styleUrls: ['./roster-entries.component.scss'],
    animations: [fadeIn],
})
export class RosterEntriesComponent {
    @Input() entries: Employee[];
    @Output() goToLandingPage = new EventEmitter();
    @ViewChild('cardHeader', {read: ElementRef}) cardHeader: ElementRef;
    @ViewChild('cardFooter', {read: ElementRef}) cardFooter: ElementRef;

    constructor(private renderer: Renderer2) {}

    startEnrollment(): void {
        this.goToLandingPage.emit();
    }

    onScroll(e: Event): void {
        const scrollable = <HTMLElement>e.target;
        const header = this.cardHeader.nativeElement;
        const footer = this.cardFooter.nativeElement;
        const canScrollUp = scrollable.scrollTop > 0;
        const canScrollDown = scrollable.scrollTop + scrollable.clientHeight < scrollable.scrollHeight;
        this.renderer.removeClass(header, 'box-shadow-below');
        this.renderer.removeClass(footer, 'box-shadow-above');
        if (canScrollUp) {
            this.renderer.addClass(header, 'box-shadow-below');
        }
        if (canScrollDown) {
            this.renderer.addClass(footer, 'box-shadow-above');
        }
    }
}
