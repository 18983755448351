<div class="ws card-body">

    <!-- PROMPT -->
    <div class="ws__prompt"
        *ngIf="status && status === 'listening'"
        [@fadeIn]>
        <div class="ws__prompt__content">
            <div class="ws__prompt__heading">Read This</div>
            <div class="ws__prompt__body">
                {{mssg?.prompt}}
            </div>
        </div>
    </div>

    <!--  STATUS -->
    <cr-avid-call-status *ngIf="status && callStatusConditions.includes(status)"
        [mssg]="mssg"
        [pin]="pin"
        [phone]="phone"
        [@fadeIn]>
    </cr-avid-call-status>

    <!-- PROGRESS -->
    <div class="ws__progress"
        [@fadeIn]>
        <div class="ws__progress-label">PROGRESS:</div>
        <div class="progress">
            <div class="progress-bar"
                role="progressbar"
                aria-label="progress bar"
                [ngStyle]="{'width': progress + '%'}">
            </div>
        </div>
    </div>
</div>
