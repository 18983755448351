<div class="disconnected">
    <div class="disconnected__error">
        <div class="disconnected__error__icon">
            <cr-avid-warning-svg></cr-avid-warning-svg>
        </div>
        <div class="disconnected__error__text fs-14px">
            <span class="fw-bold">
                Hmm, it looks like you're not connected.
            </span>
            <span>Try refreshing the page</span>
        </div>
    </div>
</div>
