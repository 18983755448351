import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { ReadAloudComponent } from './components/read-aloud/read-aloud.component';
import { RosterEntriesComponent } from './components/roster-entries/roster-entries.component';
import { HeaderComponent } from './components/header/header.component';
import { AlreadyEnrolledComponent } from './components/already-enrolled/already-enrolled.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxTypedJsModule} from 'ngx-typed-js';
import { CallStatusComponent } from './components/call-status/call-status.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { PhoneAndPinComponent } from './components/phone-and-pin/phone-and-pin.component';
import { LandingPageGenericComponent } from './components/landing-page-generic/landing-page-generic.component';
import { LandingPageFooterComponent } from './components/landing-page-footer/landing-page-footer.component';
import { GetStartedComponent } from './components/get-started/get-started.component';
import { SharedModule } from './shared/shared.module';
import { CallUsComponent } from './components/call-us/call-us.component';
import { UnrecognizedEnrollmentKeyComponent } from './components/unrecognized-enrollment-key/unrecognized-enrollment-key.component';
import { DisconnectedComponent } from './components/disconnected/disconnected.component';
import { BiometricConsentComponent } from './components/biometric-consent/biometric-consent.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    ReadAloudComponent,
    RosterEntriesComponent,
    HeaderComponent,
    AlreadyEnrolledComponent,
    CallStatusComponent,
    WelcomeComponent,
    BiometricConsentComponent,
    PhoneAndPinComponent,
    LandingPageGenericComponent,
    LandingPageFooterComponent,
    GetStartedComponent,
    CallUsComponent,
    UnrecognizedEnrollmentKeyComponent,
    DisconnectedComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxTypedJsModule,
    SharedModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
