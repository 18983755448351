import { Component, Input } from '@angular/core';
import { ProcessingStatus, WebSocketMessage } from 'src/app/models/models';
import { EnrollmentStatusService } from 'src/app/services/enrollment-status.service';

@Component({
    selector: 'cr-avid-call-status',
    templateUrl: './call-status.component.html',
    styleUrls: ['./call-status.component.scss'],
})
export class CallStatusComponent {
    status: ProcessingStatus;

    @Input() pin: string | undefined;
    @Input() phone: string | undefined;

    @Input()
    get mssg(): WebSocketMessage | undefined {
        return this._mssg;
    }
    set mssg(mssg: WebSocketMessage | undefined) {
        this.status = this.enrollmentService.createCallStatus(mssg);
    }
    private _mssg: WebSocketMessage;

    constructor(private enrollmentService: EnrollmentStatusService) {}
}
